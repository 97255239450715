.arabicText{
  direction: rtl;

}
.arabicDirection{
  direction: rtl;
  & select, & .priceRange{
    text-align: right !important;
    padding: 11px 16px 7px 40px !important;
    background-position: 11px !important;
  }
}
.latestServicesWrapper {
  & .searvicesBox {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;

    & .servicesHeading {
      font-size: 32px;
      font-weight: 500;
      line-height: 52.8px;
      letter-spacing: 0.002em;
      color: #0b0d14;
    }

    & .searvicesFilter {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      & .selectBox {
        flex: 1;

        @media (max-width: 960px) {
          flex: unset;
        }

        & select {
          border-radius: 16px;
          border: 1px solid #f5f6f8;
          background-color: #f5f6f8;
          box-shadow: none;
          width: 160px;
          color: #0b0d14;
          font-family: 'Montserrat';
          font-size: 16px;
          font-weight: 500;
          line-height: 26.4px;
          letter-spacing: 0.002em;
          text-align: left;
          height: 48px;
          background-position: calc(100% - 11px);
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 8.41675L10 13.4167L15 8.41675' stroke='%23464A56' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          @media (max-width: 768px) {
            width: 100%;
            padding-right: 50px;
          }
        }
      }
    }
  }
}
.priceRange {
  height: 48px;
  padding: 11px 20px;
  background-color: #f5f6f8;
  border-radius: 16px;
  width: 148px;
  position: relative;
  background-position: calc(100% - 11px);
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 8.41675L10 13.4167L15 8.41675' stroke='%23464A56' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  & label {
    color: #0b0d14;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    line-height: 26.4px;
    letter-spacing: 0.002em;
    padding: 0;
  }
}
.priceInput {
  position: absolute;
  z-index: 9;
  width: 260px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 12px 4px #00000014;
  padding: 20px;
  top: 50px;
  right: 0;
  @media (max-width: 560px) {
    left: 0;
    right: unset;
  }
}

.slotWrapper {
    padding: 130px 0 27px;
    @media(max-width: 768px){
        padding: 100px 0 27px;
    }

    & .slotRow {
        display: flex;
        gap: 53px;

        /* grid-template-columns: 40% 60%; */
        @media(max-width: 840px) {
            flex-wrap: wrap;
        }

        & .slotLeft {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 40%;

            @media(max-width: 1120px) {
                width: 60%;
            }

            @media(max-width: 920px) {
                width: 70%;
            }

            @media(max-width: 840px) {
                width: 100%;
            }

            & h2 {
                font-size: 48px;
                font-weight: 500;
                line-height: 50px;
                color: #030712;
                margin-top: 0;
                margin-bottom: 50px;

                @media(max-width: 768px) {
                    font-size: 32px;
                    line-height: 48px;
                    margin-bottom: 30px;
                }
                & img{
                    width: 48px;
                    height: 48px;
                    @media(max-width: 768px) {
                        width: 42px;
                        height: 42px;
                    }
                }
            }

            & p {
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0.3461538851261139px;
                color: #404852;
                margin: 0;
                padding: 0;
            }
        }

        & .slotRight {
            width: 60%;

            @media(max-width: 1120px) {
                width: 40%;
            }

            @media(max-width: 920px) {
                width: 30%;
            }

            @media(max-width: 840px) {
                width: 100%;
            }

            & .bottomleft {
                position: relative;

                @media(max-width: 840px) {
                    text-align: center;
                }

                & .slotImage {
                    width: 100%;

                    @media(max-width: 840px) {
                        width: 65%;
                    }

                    @media(max-width: 520px) {
                        width: 100%;
                    }
                }

                & .centerImage {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    & img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.slotMobileStore{
    margin-top: 41px;
}
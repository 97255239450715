.merchantWrapper {
    padding: 80px 0 100px;

    & .merchantHeading {
        font-size: 72px;
        font-weight: 600;
        line-height: 88px;
        color: #030712;
        letter-spacing: -0.02em;
        text-align: center;
        margin-top: 0;
        margin-bottom: 24px;

        @media(max-width: 1024px) {
            font-size: 58px;
            line-height: 68px;
        }

        @media(max-width: 768px) {
            font-size: 42px;
            line-height: 58px;
        }

        @media(max-width: 460px) {
            font-size: 32px;
            line-height: 42px;
        }
    }

    & .merchantSubHeading {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #4B5563;
        padding: 0 28%;
        margin: 0;

        @media(max-width: 920px) {
            padding: 0 10%;
        }

        @media(max-width: 600px) {
            padding: 0;
        }
    }

    & .bottomButton {
        text-align: center;
        margin-top: 32px;

        & .startFreeButton {
            min-height: auto;
            border-radius: 12px;
            padding: 8px 24px;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
            cursor: pointer;
            color: #1e1e1e;
            background-color: #FFD046;
            border: 1px solid #FFD046;
            width: auto;
            margin: 0 auto;
        }
    }
}
.mobileStore{
    margin: 49px 0 0;
}
.merchantMainWrapper {
  background-color: #fff;
  & footer {
    border-top: unset;
    & > div {
      padding-top: 28px !important;
    }
  }

  & .mainWrapper {
    max-width: 1228px;
    margin: 0 auto;
    width: 100%;

    & .innerWrapper {
      padding: 0 24px;
    }
  }

  & .calenderImage {
    & img {
      width: 100%;
    }
  }

  & .featureSection {
    padding: 24px 0 73px;

    @media (max-width: 768px) {
      padding: 0 0 73px;
    }
    & .title {
      /* font-family: SF Pro Text; */
      font-size: 48px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -0.02em;
   
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #030712;
      margin: 0 0 24px;
      padding: 0;
      max-width: 650px;
      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 42px;
      }
    }
    & .subTitle {
      /* font-family: Plus Jakarta Sans; */
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
   
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: 0 0 67px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 40px;
      }
    }
    & .blockTitle {
      /* font-family: SF Pro Text; */
      font-size: 30px;
      font-weight: 600;
      line-height: 36px;
   
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #030712;
      margin: 0 0 8px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 34px;
      }
    }
    & .blockText {
      font-family: Actor;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
   
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #6b7280;
      margin: 0 0 0px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    & .featureBlocks {
      display: flex;
      gap: 32px;
      margin: 0 0 32px;
      @media (max-width: 1023px) {
        gap: 24px;
        margin: 0 0 24px;
      }
      @media (max-width: 767px) {
        flex-direction: column;
      }
      & > div {
        &:nth-child(1) {
          max-width: 456px;
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
      & .featureBlock {
        border: 1px solid #e5e7eb;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 1023px) {
          width: 48%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
        & .textContent {
          padding: 32px 32px 40px;
          @media (max-width: 767px) {
            padding: 16px;
          }
        }
      }
    }
    & .featureBlocksTwo {
      & > div {
        &:nth-child(1) {
          max-width: unset;
        }
        &:nth-child(2) {
          max-width: 456px;
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
    }

    & img {
      width: 100%;
      object-fit: contain;
      border-radius: 16px;
    }
  }
}
.arabicDirection {
  direction: rtl;
}
.youtubeVideo {
  border-radius: 29px;
  max-width: 1111px;
  margin: 0 auto;
  display: block;
  @media (max-width: 1023px) {
    height: 550px;
  }
  @media (max-width: 767px) {
    height: 400px;
  }
  @media (max-width: 580px) {
    height: 350px;
  }
  @media (max-width: 460px) {
    height: 300px;
  }
  @media (max-width: 400px) {
    height: 260px;
  }
}

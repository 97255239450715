.dealTypesWrapper {
  margin-top: 110px;
  & .dealTopRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 66px;

    @media (max-width: 768px) {
      margin-bottom: 33px;
    }

    & .dealHeading {
      font-size: 32px;
      font-weight: 600;
      line-height: 38.19px;
      color: #000;
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 28.19px;
      }
    }

    & .viewAllLink {
      color: #000;
      font-size: 15px;
      font-weight: 500;
      line-height: 17.9px;
      cursor: pointer;
    }
  }

  & .dealCardsWrapper {
    & .cardBox {
      & img {
      }
    }
  }

  & .signUp {
    font-size: 32px;
    color: #0b0d14;
    font-weight: 500;
    text-align: center;
    line-height: 52.8px;
    margin-bottom: 134px;
    letter-spacing: 0.002em;
    /* font-family: 'SF Pro Text'; */

    @media (max-width: 1260px) {
      margin-top: 50px;
      margin-bottom: 100px;
    }

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 50px;
    }

    & span {
      font-weight: 800;
      font-style: italic;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* padding: 0 4px; */
  column-gap: 30px;

  @media (max-width: 1260px) {
    display: none;
  }
}
.arabicText {
  direction: rtl;
}
.arabicRowReverse {
  direction: rtl;
  & .gridContainer {
    & h3{
      text-align: right !important;
    }
    & .icon {
      right: unset !important;
      left: 0;
    }
    &>div{
      &:nth-child(1){
        &>div{
          &:nth-child(1){
            & .icon {
              right: unset !important;
              left: -20px;
              @media (max-width: 1260px) {
                left: -10px;
              }
            }
          }
          &:nth-child(2){
            & .icon {
              right: unset !important;
              left: 35px;
              @media (max-width: 767px) {
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0 0 144px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &>div{
    &:nth-child(1){
      &>div{
        &:nth-child(1){
          height: 172px;
          & .icon {
            top: -20px;
            right: -20px;
            @media (max-width: 1260px) {
              top: 20px;
              right: -10px;
            }
          }
        }
        &:nth-child(2){
          height: 390px;
          @media (max-width: 767px) {
            height: 240px;
          }
          & .icon {
            top: 40px;
            right: 35px;
            @media (max-width: 767px) {
              right: 0;
              top: 10px;
            }
          }
        }
      }
    }
    &:nth-child(2){
      &>div{
        &:nth-child(1){
          height: 326px;
          @media (max-width: 767px) {
            height: 240px;
          }
        }
        &:nth-child(2){
          height: 236px;
        }
      }
    }
    &:nth-child(3){
      &>div{
        &:nth-child(1){
          height: 258px;
          @media (max-width: 767px) {
            height: 240px;
          }
        }
        &:nth-child(2){
          height: 304px;
          @media (max-width: 767px) {
            height: 240px;
          }
        }
      }
    }
  }
  .card {
    background: linear-gradient(304.62deg, #ffd046 29.58%, #ffe6a0 82.56%);
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    color: #000;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
  }
  & .twoCardColumn {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 767px) {
      gap: 24px;
    }
  }

  .icon {
    position: absolute;
    right: 0;
    top: 10px;
    & img {
      width: 100%;
      height: 100%;
      @media (max-width: 1260px) {
        width: 120px;
        height: 120px;
      }
    }
  }

  h3 {
    /* font-family: SF Pro Text; */
    font-size: 20px;
    font-style: italic;
    font-weight: 800;
    line-height: 23.87px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #000;
    margin: 0 0 10px;
    padding: 0;
    max-width: 200px;
  }

  .link {
    /* font-family: SF Pro Text; */
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 16.71px;
    text-align: center;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
    transition: color 0.3s ease;
  }

  .link:hover {
    /* color: #005bb5; */
    font-weight: 600;
  }
}

.mobileRow {
  display: none;

  @media (max-width: 1260px) {
    gap: 10px;
    display: grid;
    padding-bottom: 10px;
    grid-template-columns: 1fr 1fr;
  }

  & .detailImage {
    min-width: 35%;

    @media (max-width: 600px) {
      min-width: 100%;
    }

    & img {
      width: 100%;
      height: 200px;
      object-fit: fill;

      @media (max-width: 600px) {
        height: 130px;
      }
    }
  }
}

.column {
  flex: calc((100% - (30px * 2)) / 3);
  max-width: calc((100% - (30px * 2)) / 3);
  /* padding: 0 15px; */
  cursor: pointer;

  & img {
    &:hover {
      border-radius: 10px;
      box-shadow: 0 2px 3px 0 rgba(216, 220, 230, 0.7);
    }
  }

  &:last-child {
    & img {
      &:first-child {
        height: 35%;
      }

      &:last-child {
        height: 43%;
      }
    }
  }
}

.column img {
  margin-top: 30px;
  vertical-align: middle;

  &:first-child {
    margin-top: 0;
  }
}

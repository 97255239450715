.bestDealsWrapper {
    display: grid;
    grid-template-columns: 1.4fr 1fr;
    gap: 100px;
    padding: 90px 0;

    @media(max-width: 960px) {
        grid-template-columns: 1fr;
    }

    @media(max-width: 1320px) {
        gap: 50px;
    }

    & .dealsLeft {
        position: relative;

        @media(max-width: 1320px) {
            text-align: right;
        }

        @media(max-width: 960px) {
            text-align: center;
        }


        & .dealsImage {
            object-fit: contain;

            @media(max-width: 460px) {
                width: 100%;
            }
        }

        & .dealsIcon {
            position: absolute;
            left: 48px;
            top: 48px;
            width: 227px;
            height: 227px;
            @media(max-width: 1320px) {
                width: 180px;
                height: 180px;
            }

            @media(max-width: 1024px) {
                left: 40px;
            }

            @media(max-width: 960px) {
                left: 159px;
            }

            @media(max-width: 768px) {
                left: 60px;
            }

            @media(max-width: 600px) {
                left: 24px;
                top: 24px;
                width: 130px;
                height: 130px;
            }

            @media(max-width: 500px) {
                left: 20px;
                width: 100px;
                height: 100px;
            }
        }
    }

    & .dealsRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        & h2 {
            font-size: 48px;
            font-weight: 500;
            line-height: 50px;
            color: #030712;
            margin-top: 0;
            margin-bottom: 58px;

            @media(max-width: 768px) {
                font-size: 32px;
                line-height: 48px;
                margin-bottom: 30px;
            }
            @media (min-width: 1360px) {
                margin-right: -50px;
            }
        }

        & p {
            color: #404852;
            padding: 0;
            margin: 0;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.3461538851261139px;
            @media (min-width: 1360px) {
                margin-right: -30px;
            }
        }
    }
}
.dealMobileStore{
    margin-top: 53px;
}
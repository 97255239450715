.topCategoriesWrapper {
  padding: 63px 0 135px;
  @media (max-width: 1023px) {
    padding: 40px 0 80px;
    
  }
  & .topCategoriesHeading {
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.4000000059604645px;
    color: #000;
    margin-bottom: 64px;

    @media (max-width: 768px) {
      font-size: 32px;
    }
    @media (max-width: 767px) {
        font-size: 24px;
        line-height: 28.19px;
      }
  }

  & .categoriesCard {
    display: grid;
    gap: 64px;
    grid-template-columns: repeat(7, 1fr);
    max-width: 1228px;
    margin: 0 auto;
    @media (max-width: 1120px) {
      gap: 40px;
    }

    @media (max-width: 960px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(5, 1fr);
      column-gap: 24px;
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 460px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 460px) {
      grid-template-columns: repeat(2, 1fr);
    }

    & .categoriesList {
      text-align: center;

      & .catgegoriesimage {
        & img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: contain;

          @media (max-width: 768px) {
            width: 70%;
          }
        }
      }

      & .categoriesTitle {
        color: #000;
        font-size: 12px;
        margin-top: 8px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
}
.arabicText{
  direction: rtl;
& .viewAllCircle{
  & svg{
    transform: rotate(180deg);
  }
}
}
.arabicRowReverse{
  flex-direction: row-reverse;
}
.categoriesImage {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & img{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    @media (max-width: 1200px) {
      width: 80px;
      height: 80px;
    }
  }
}
.viewAllCircle {
  background: #007bff33;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
  @media (max-width: 1200px) {
    width: 80px;
    height: 80px;
  }
}
.viewAllButton {
  cursor: pointer;
  background-color: unset;
  min-height: unset;
}

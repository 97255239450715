.popularDealsWrapper {
  & .polularBox {
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 43px;
    flex-wrap: wrap;

    & .servicesHeading {
      font-size: 32px;
      font-weight: 500;
      color: #0b0d14;
      line-height: 52.8px;
      letter-spacing: 0.002em;
    }

    & .searvicesFilter {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      & .selectBox {
        flex: 1;

        @media (max-width: 960px) {
          flex: unset;
        }

        & select {
          border-radius: 16px;
          border: 1px solid #f5f6f8;
          background-color: #f5f6f8;
          box-shadow: none;
          width: 160px;
          color: #0b0d14;
          font-family: 'Montserrat';
          font-size: 16px;
          font-weight: 500;
          line-height: 26.4px;
          letter-spacing: 0.002em;
          text-align: left;
          height: 48px;
          background-position: calc(100% - 11px);
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 8.41675L10 13.4167L15 8.41675' stroke='%23464A56' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          @media (max-width: 768px) {
            width: max-content;
            padding-right: 50px;
          }
        }
      }
    }
  }

  & .popularBody {
    & .discountVoucherImage{
      background-image: url('./../../../../assets/images/discountimage1.png');
      margin-bottom: 10px;
      padding: 97px 0 78px 126px;

      background-size: 100% 100%;
      height: 450px;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      left: 0;
    }
    & .voucherContent{
      z-index: 1;
    }
    & .discountVoucher {
      margin-bottom: 10px;
      padding: 97px 0 78px 126px;

      background-size: 100% 100%;
      height: 450px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      position: relative;

      @media (max-width: 1024px) {
        padding: 30px 0 30px 30px;
      }

      @media (max-width: 768px) {
        height: 340px;
      }

      @media (max-width: 580px) {
        height: 220px;
        padding: 20px 0 20px 20px;
      }
      & .fromTopText {
        position: absolute;
        right: 76px;
        top: 74px;
        padding: 0 100px;

        /* font-family: SF Pro Text; */
        font-size: 24px;
        font-style: italic;
        font-weight: 600;
        line-height: 74px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000;
        background-color: #ffd046;
        width: 510px;
        white-space: pre;
        @media (max-width: 1023px) {
          padding: 0 20px;
          font-size: 18px;
        }
        @media (max-width: 767px) {
          padding: 0 20px;
          font-size: 16px;
          line-height: 30px;
          width: auto;
        }
        @media (max-width: 580px) {
          font-size: 12px;
          line-height: 20px;
          right: 0;
          top: 35px;
          padding: 8px;
        }
      }

      & .standardText {
        font-size: 56px;
        font-style: italic;
        font-weight: 800;
        line-height: 70px;
        color: #000;

        @media (max-width: 768px) {
          font-size: 42px;
          line-height: 50px;
        }

        @media (max-width: 580px) {
          font-size: 20px;
          line-height: 30px;
        }
      }

      & .largeDiscount {
        font-size: 156px;
        font-style: italic;
        font-weight: 800;
        line-height: 150px;
        color: #000;

        @media (max-width: 768px) {
          font-size: 100px;
          line-height: 100px;
        }

        @media (max-width: 600px) {
          font-size: 50px;
          line-height: 60px;
        }
      }
    }
  }
}
.arabicText {
  direction: rtl;
}
.arabicRowReverse {
  direction: rtl;
  & select, & .priceRange{
    text-align: right !important;
    padding: 11px 16px 7px 40px !important;
    background-position: 11px !important;
  }
  & .discountVoucherImage{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  & .discountVoucher{
    padding: 97px 126px 78px 0 !important;  
    @media (max-width: 1023px) {
      padding: 30px 30px 30px 0px !important;
    }
    @media (max-width: 1023px) {
      padding: 20px 30px 20px 0px !important;
    }
  }
  & .fromTopText {
    right: unset !important;
    left: 76px;
    @media (max-width: 580px) {
      left: 0;
    }
  }
}
.priceRange {
  height: 48px;
  padding: 11px 20px;
  background-color: #f5f6f8;
  border-radius: 16px;
  width: 148px;
  position: relative;
  background-position: calc(100% - 11px);
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 8.41675L10 13.4167L15 8.41675' stroke='%23464A56' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  & label {
    color: #0b0d14;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    line-height: 26.4px;
    letter-spacing: 0.002em;
    padding: 0;
  }
}
.priceInput {
  position: absolute;
  z-index: 9;
  width: 260px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 12px 4px #00000014;
  padding: 20px;
  top: 50px;
  right: 0;
  @media (max-width: 560px) {
    left: 0;
    right: unset;
  }
}

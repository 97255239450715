.arabicDirection{
    direction: rtl;
    & .newsletterBox{
        transform: scaleX(-1);
    }
}
.newsLetterWrapper {
    & .newsletterBox {
        padding: 100px 20px;
        background-size: 100% 100%;
        background-image: url('../../../../assets/images/newsletter.png');
        background-position: center;
        @media(max-width: 600px) {
            background-size: cover;
        }

        & .joinNewsletter {
            font-size: 40px;
            font-weight: 500;
            line-height: 44px;
            text-align: center;
            color: #141718;
            margin-bottom: 8px;
            letter-spacing: -0.4000000059604645px;

            @media(max-width: 768px) {
                font-size: 32px;
            }
        }

        & .productPromotion {
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            color: #141718;
            margin-bottom: 32px;
        }

        & .newsletterInput {
            width: 540px;
            margin: 0 auto;
            position: relative;
            display: flex;
            align-items: center;

            @media(max-width: 1220px) {
                width: 100%;
                max-width: 340px;
            }

            & .emailIcon {
                position: absolute;
                left: 10px;
                top: 45%;
                transform: translate(-50%, -50%);
            }

            & input {
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-radius: 0;
                padding-left: 30px;
                padding-right: 55px;
                background-color: #f2f4f600;
                border-bottom: 1px solid #6C727580;

                &:focus {
                    border: 0;
                    box-shadow: none;
                    border-bottom: 1px solid #6C727580;
                }
            }

            & .signupLink {
                right: 0;
                top: 50%;
                cursor: pointer;
                position: absolute;
                transform: translate(0, -50%);
            }
        }
    }
}
.mainWrapper {
    background-color: #fff;
}

.innerBox {
    max-width: calc(1360px + 48px);
    margin: 0 auto;
    width: 100%;
    padding: 0 24px 30px;

    @media(max-width: 1260px) {
        max-width: 100%;
    }
}
.searchContents{
    padding: 0 !important;
}
.latestServicesSection{
   &>div{
    &>div{
        &>div{
           @media (max-width: 767px) {
            padding: 0 0 0 0 !important;
           }
        }
    }
   }
}